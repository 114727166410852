import(/* webpackMode: "eager" */ "/home/runner/work/jgo.me/jgo.me/.yarn/__virtual__/next-virtual-c88112e973/4/.yarn/berry/cache/next-npm-14.2.4-37fb4e5b51-10c0.zip/node_modules/next/dist/client/components/app-router.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/jgo.me/jgo.me/.yarn/__virtual__/next-virtual-c88112e973/4/.yarn/berry/cache/next-npm-14.2.4-37fb4e5b51-10c0.zip/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/jgo.me/jgo.me/.yarn/__virtual__/next-virtual-c88112e973/4/.yarn/berry/cache/next-npm-14.2.4-37fb4e5b51-10c0.zip/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/jgo.me/jgo.me/.yarn/__virtual__/next-virtual-c88112e973/4/.yarn/berry/cache/next-npm-14.2.4-37fb4e5b51-10c0.zip/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/jgo.me/jgo.me/.yarn/__virtual__/next-virtual-c88112e973/4/.yarn/berry/cache/next-npm-14.2.4-37fb4e5b51-10c0.zip/node_modules/next/dist/client/components/not-found-boundary.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/jgo.me/jgo.me/.yarn/__virtual__/next-virtual-c88112e973/4/.yarn/berry/cache/next-npm-14.2.4-37fb4e5b51-10c0.zip/node_modules/next/dist/client/components/render-from-template-context.js");
